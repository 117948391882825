import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { zeroPad } from 'react-countdown'
import { gsap } from 'gsap'

import staggerAnimation from '../../utils/staggerAnimation'
import StartNav from '../../components/StartNav'
import CountDown from '../../components/CountDown'
import withMemo from '../../decorators/withMemo'

import useStyles from './styles'


const GameCountdownTemplate = (props) => {
  const classes = useStyles(props)
  const { className, text, startNavProps, remainingTime } = props


  // animations
  const $animatedWrapper = useRef()

  useEffect(() => {
    const timeline = gsap.timeline()

    if ($animatedWrapper.current && text) {
      const animatedChildren = Array.from($animatedWrapper.current.children).filter((child) => !child.classList.contains('is-notstaggered'))

      staggerAnimation(0, animatedChildren, timeline, 0.5, '+=0.2', 0.5)
      timeline.play()
    }

    // cleanup
    return () => {
      timeline.clear()
    }
  }, [text])

  return (
    <div
      className={cx(className, classes.container)}
      ref={$animatedWrapper}
    >
      <div className={classes.countDownWrapper}>
        <CountDown
          className={classes.countDown}
          countDownDate={remainingTime}
          countDownProps={{
            zeroPadTime: 2,
            renderer: (thisProps) => (
              `${zeroPad(thisProps.hours)}:${zeroPad(thisProps.minutes)}:${zeroPad(thisProps.seconds)}`
            ),
          }}
        />
        <svg
          className={classes.countDownImage}
          aria-hidden="true"
          width="232"
          height="232"
          viewBox="0 0 232 232"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="116"
            cy="116"
            r="93"
            transform="rotate(165 116 116)"
            stroke="url(#paint0_linear)"
            strokeWidth="2"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="114.292"
              y1="280.881"
              x2="258.054"
              y2="134.449"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop
                offset="0.46004"
                stopColor="white"
              />
              <stop
                offset="1"
                stopColor="white"
                stopOpacity="0.01"
              />
            </linearGradient>
          </defs>
        </svg>
      </div>
      {text && (
        <div className={classes.text}>
          {text}
        </div>
      )}
      {startNavProps && (
        <StartNav
          className={classes.nav}
          {...startNavProps}
        />
      )}
    </div>
  )
}

export const GameCountdownTemplatePropTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.string,
  startNavProps: PropTypes.shape(),
  remainingTime: PropTypes.string,
}

GameCountdownTemplate.propTypes = GameCountdownTemplatePropTypes

GameCountdownTemplate.defaultProps = {
  className: null,
  text: null,
  startNavProps: null,
  remainingTime: null,
}

export default withMemo()(GameCountdownTemplate)
