import React, { useMemo, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { navigate } from 'gatsby-plugin-intl'

import { useIntl } from '../../intl/intl'
import { withMemo, withWebsiteOpen, withGameClosed } from '../../decorators'
import LayoutGame from '../../components/LayoutGame'
import GameCountdownTemplate from '../../templates/GameCountdown'
import router from '../../router'
import routes from '../../router/routes'
import useConfiguration from '../../hooks/useConfiguration'
import useLocalStorage from '../../hooks/useLocalStorage'
import * as appConfiguration from '../../configuration'


const GameWait = (props) => {
  const { pageContext, location } = props
  const t = useIntl()
  const { seoFallback } = useConfiguration()
  const { userCooldownDate, userCooldownReason, resetCooldown } = useLocalStorage()

  const layoutProps = useMemo(() => ({
    videoBackgroundSources: [
      {
        src: t('background_video_path'),
        type: 'video/mp4',
      },
    ],
    seo: {
      lang: pageContext?.language ?? null,
      title: seoFallback?.title ?? '',
      description: seoFallback?.description?.description ?? '',
      image: appConfiguration.app.url + seoFallback?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
      url: location?.href ?? '',
      robots: 'noindex, follow',
    },
  }), [location.href, pageContext.language, seoFallback.description.description, seoFallback.picture.localFile.childImageSharp.fluid.src, seoFallback.title, t])


  const gamePageCountdownProps = useMemo(() => ({
    remainingTime: userCooldownDate,
    text: userCooldownReason ? t(`waiting_message_${userCooldownReason}`) : '',
  }), [t, userCooldownDate, userCooldownReason])

  const startNavProps = {
    buttons: [
      {
        text: t('home_lookbook'),
        variant: 'bigtransparent',
        gatsbyLink: {
          route: router(pageContext?.isVip ? routes.vipLooks.path : routes.looks.path),
        },
      },
    ],
  }

  useEffect(() => {
    // When waiting is complete
    const timer = setTimeout(() => {
      resetCooldown()
      navigate(router(pageContext?.isVip ? routes.vipGame.path : routes.game.path))
    }, Math.floor((new Date(userCooldownDate) - new Date())))

    return () => clearTimeout(timer)
  }, [userCooldownDate, resetCooldown, pageContext])

  return (
    <LayoutGame {...layoutProps}>
      <GameCountdownTemplate
        startNavProps={startNavProps}
        {...gamePageCountdownProps}
      />
    </LayoutGame>
  )
}

GameWait.propTypes = {
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}

GameWait.defaultProps = {
  pageContext: null,
  location: null,
}

export default withGameClosed(withWebsiteOpen(withMemo()(GameWait)))
