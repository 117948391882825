import { createUseStyles } from 'react-jss'

import { desktop, maxDesktop, notDesktop } from '../../theme'


export default createUseStyles({
  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    minHeight: 'calc(var(--vh, 1vh) * 50)',
    fallbacks: {
      minHeight: '50vh',
    },
    '& > *': {
      width: '100%',
      margin: 0,
    },
    '& > *:not(.is-notstaggered)': {
      opacity: 0, // to prevent FOUC
    },
    '& > * + *': {
      marginTop: '2rem',
    },
    '& > * + $text': {
      marginTop: 'auto',
    },
    '& > *:not($text) + $nav': {
      marginTop: 'auto',
    },
    ...maxDesktop({
      '& > * + $text:not(:last-child)': {
        marginBottom: '5rem', // added to avoid having the text stick to the nav....
      },
    }),
  },
  header: {},
  countDownWrapper: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    ...notDesktop({
      '@media (orientation: landscape)': {
        height: '40rem',
      },
    }),
  },
  countDown: {
    fontSize: '3.6rem',
  },
  countDownImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '23.2rem',
    width: '23.2rem',
    marginTop: '-11.6rem',
    marginLeft: '-11.6rem',
    transformOrigin: 'center center',
    animation: '$rotating 12s linear infinite',
    willChange: 'transform',
    ...notDesktop({
      '@media (orientation: landscape)': {
        opacity: 0,
      },
    }),
  },
  text: {
    maxWidth: '40rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '8rem',
    fontSize: '1.2rem',
    lineHeight: '1.2',
    textAlign: 'center',
    '& > *': {
      margin: 0,
    },
    '& > * + *': {
      marginTop: '0.5rem',
    },
    ...notDesktop({
      '@media (orientation: landscape)': {
        minHeight: 0,
        marginTop: '2rem',
        marginBottom: '2rem',
      },
    }),
    ...desktop({
      '& > * + *': {
        marginTop: '1rem',
      },
    }),
  },
  nav: {},
})
